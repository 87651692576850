<template>
  <v-row justify="center" class="mt-4 mb-8" no-gutters>
    <v-col class="text-right" cols="12" xs="12" sm="10" md="8">
      <v-btn color="primary" @click="handleLogout">Uitloggen</v-btn>
    </v-col>

    <v-col align-content="center" cols="12" xs="12" sm="10" md="8">
      <v-form @submit.prevent="updateProfile" ref="profileForm">
        <v-subheader class="pa-0">Profiel</v-subheader>
        <v-card class="themed-color">
          <div class="text-center pa-12"
               v-if="profileFormLoading">
            <v-progress-circular
                :width="3"
                color="accent"
                indeterminate
            ></v-progress-circular>
          </div>
          <v-card-text v-else>
            <v-text-field
                v-model="profileForm.name"
                label="Naam"
                hint="Hoe heet je?"
                :rules="[(v) => !!v || 'Naam is verplicht', v => serverError('name', v)]"
            ></v-text-field>
            <v-text-field
                v-model="profileForm.email"
                label="Email"
                hint="Welk e-mail adres wil je gebruiken om in te loggen?"
                :rules="[(v) => !!v || 'E-mail is verplicht', v => serverError('email', v)]"
            ></v-text-field>
          </v-card-text>
          <v-col class="text-right" cols="12">
            <v-btn color="primary" @click="updateProfile" type="submit">
              <v-icon small class="mr-2">fa-save</v-icon>
              Opslaan
            </v-btn>
          </v-col>
        </v-card>
      </v-form>

      <v-form @submit.prevent="updatePassword" ref="passwordForm">
        <v-subheader class="pa-0 mt-2">Wachtwoord</v-subheader>
        <v-card class="themed-color">
          <div class="text-center pa-12"
               v-if="passwordFormLoading">
            <v-progress-circular
                :width="3"
                color="accent"
                indeterminate
            ></v-progress-circular>
          </div>
          <v-card-text v-else>
            <v-text-field
                v-model="passwordForm.currentPassword"
                :append-icon="passwordShow ? 'fas fa-eye-slash' : 'fas fa-eye'"
                :type="passwordShow ? 'text' : 'password'"
                label="Huidig wachtwoord"
                @click:append="passwordShow = !passwordShow"
                :rules="[(v) => serverError('currentPassword', v)]"

            ></v-text-field>
            <v-text-field
                v-model="passwordForm.password"
                :append-icon="passwordShow ? 'fas fa-eye-slash' : 'fas fa-eye'"
                :type="passwordShow ? 'text' : 'password'"
                label="Nieuw wachtwoord"
                hint="kies een veilig en lang wachtwoord"
                @click:append="passwordShow = !passwordShow"
                :rules="[(v) => v === passwordForm.password || 'Beide wachtwoorden moeten hetzelfde zijn', (v) => serverError('password', v)]"
            ></v-text-field>
            <v-text-field
                v-model="passwordForm.password_confirmation"
                :append-icon="passwordShow ? 'fas fa-eye-slash' : 'fas fa-eye'"
                :type="passwordShow ? 'text' : 'password'"
                label="Nieuw wachtwoord ter controle"
                hint="Hetzelfde wachtwoord"
                @click:append="passwordShow = !passwordShow"
                :rules="[(v) => v === passwordForm.password || 'Beide wachtwoorden moeten hetzelfde zijn', (v) => serverError('password', v)]"
            ></v-text-field>
          </v-card-text>
          <v-col class="text-right" cols="12">
            <v-btn color="primary" @click="updatePassword" type="submit">
              <v-icon small class="mr-2">fa-save</v-icon>
              Opslaan
            </v-btn>
          </v-col>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { fetch, updateProfile, updatePassword } from '@/api/profile';
import { mapActions } from 'vuex';

export default {
  name: 'Profile',
  data() {
    return {
      passwordForm: {
        password: '',
        password_confirmation: '',
      },
      profileForm: {
        email: '',
        name: '',
      },
      passwordFormLoading: false,
      profileFormLoading: false,
      ipFormErrors: null,
      passwordShow: false,
      errors: {},
    };
  },
  methods: {
    ...mapActions({
      logout: 'authorisation/logout',
    }),
    async handleLogout() {
      this.logout();
    },
    async updateProfile() {
      this.errors = {};
      try {
        this.profileFormLoading = true;
        await updateProfile(this.profileForm)
      } catch (error) {
        this.errors = error.response.data.errors;
        this.$refs.profileForm.validate();
      } finally {
        this.profileFormLoading = false;
      }
    },
    async updatePassword() {
      this.errors = {};
      try {
        this.passwordFormLoading = true;
        await updatePassword(this.passwordForm)
      } catch (error) {
        this.errors = error.response.data.errors;
        this.$refs.passwordForm.validate();
      } finally {
        this.passwordFormLoading = false;
      }
    },
    async getProfile() {
      const { data: { data } } = await fetch();

      this.profileForm.email = data.email;
      this.profileForm.name = data.name;
    },
    serverError(name) {
      if (this.errors !== null && typeof this.errors[name] !== 'undefined') {
        return this.errors[name][0];
      }
      return true;
    },
  },
  created() {
    this.$hero.$emit('setBreadcrumbs', false);
    this.$hero.$emit('setLarge');
    this.getProfile();
  },
};
</script>

<style scoped>

</style>
